"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navLinks = void 0;
exports.navLinks = [
    { navLinkId: 'About', scrollToId: 'aboutPageContainer' },
    { navLinkId: 'Experience', scrollToId: 'resumePageContainer' },
    { navLinkId: 'Portfolio', scrollToId: 'portfolioPageContainer' },
    { navLinkId: 'Stella', scrollToId: 'stellaPageContainer' },
    { navLinkId: 'Contact', scrollToId: 'contactPageContainer' },
];
