"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var defaultNavContextState = {
    activeNavLinkId: "",
    setActiveNavLinkId: function () { }
};
exports.NavContext = (0, react_1.createContext)(defaultNavContextState);
var NavProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(''), activeNavLinkId = _b[0], setActiveNavLinkId = _b[1];
    var providerValue = {
        activeNavLinkId: activeNavLinkId,
        setActiveNavLinkId: setActiveNavLinkId,
    };
    return ((0, jsx_runtime_1.jsx)(exports.NavContext.Provider, __assign({ value: providerValue }, { children: children })));
};
exports.default = NavProvider;
