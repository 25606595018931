"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNav = void 0;
var react_1 = require("react");
var useOnScreen_1 = require("./useOnScreen");
var NavContext_1 = require("../context/NavContext");
var useNav = function (navLinkId) {
    var ref = (0, react_1.useRef)(null);
    var setActiveNavLinkId = (0, react_1.useContext)(NavContext_1.NavContext).setActiveNavLinkId;
    var isOnScreen = (0, useOnScreen_1.useOnScreen)(ref);
    (0, react_1.useEffect)(function () {
        if (isOnScreen) {
            setActiveNavLinkId(navLinkId);
        }
    }, [isOnScreen, setActiveNavLinkId, navLinkId]);
    return ref;
};
exports.useNav = useNav;
