"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_bootstrap_1 = require("react-bootstrap");
require("./style.css");
var FooterPage = function (_a) {
    var className = _a.className;
    return ((0, jsx_runtime_1.jsx)(react_bootstrap_1.Card, __assign({ className: "text-center" }, { children: (0, jsx_runtime_1.jsx)(react_bootstrap_1.Card.Footer, __assign({ className: "text-muted" }, { children: "Dallas.Isaac1@gmail.com" })) })));
};
exports.default = FooterPage;
