"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageData = exports.portfolioData = void 0;
exports.portfolioData = [
    {
        id: 0,
        cardTitle: "HotParkr App",
        imageLink: "https://i.imgur.com/YGKEf86.png",
        githubLink: "https://project-three-skirk.herokuapp.com/",
        githubLinkText: "",
        cardText: "A fullstack MERN social media app for dog owners to find and learn about dog parks near them.",
        imageAlt: "HotParkr",
        technologies: "React, MongoDB, Express, Node.js, Bootstrap",
        deployedLink: "https://project-three-skirk.herokuapp.com/"
    },
    {
        id: 1,
        cardTitle: "HotDawgr App",
        imageLink: "https://www.bearwithme.live/assets/loader/loader_p.png",
        githubLink: "https://github.com/idallas93/HotDawgr-Application",
        githubLinkText: "HotDawgr Repository",
        cardText: "Tinder for dog owners to arrange dog play dates. This app has a working backend for user profiles built using mySQL/ Sequelize.",
        imageAlt: "HotParkr",
        technologies: "React, mySQL, Sequelize, Express, Node.js",
        deployedLink: ""
    },
    {
        id: 2,
        cardTitle: "React Quiz Game",
        imageLink: "https://www.bearwithme.live/assets/loader/loader_p.png",
        githubLink: "https://github.com/idallas93/react-quiz",
        githubLinkText: "React Quiz Application",
        cardText: "This app is similar to the vanilla javascript quiz game but was built with react and styled with Tailwind.",
        imageAlt: "ReactQuiz",
        technologies: "React, Tailwind, Sequelize, Express, Node.js",
        deployedLink: ""
    },
    {
        id: 3,
        cardTitle: "Burger App",
        imageLink: "https://www.bearwithme.live/assets/loader/loader_p.png",
        githubLink: "https://github.com/idallas93/burger-app",
        githubLinkText: "Burger Repository",
        cardText: "A food logging app that follows a MVC design pattern using Node and MySQL to query and route data/ Handlebars to generate HTML.",
        imageAlt: "Burger",
        technologies: "mySQL, Express, Javascript, Handlebars, Node.js",
        deployedLink: ""
    },
    {
        id: 4,
        cardTitle: "Vanilla Javascript Quiz",
        imageLink: "https://www.bearwithme.live/assets/loader/loader_p.png",
        githubLink: "https://github.com/idallas93/02-Homework-web-apis",
        githubLinkText: "Javascript Quiz Repository",
        cardText: "This app is a timed quiz on JavaScript fundamentals that stores high scores, this app was made using vanilla Javascript.",
        imageAlt: "VanillaJSQuiz",
        technologies: "HTML, CSS, Javascript",
        deployedLink: ""
    },
    {
        id: 5,
        cardTitle: "Employee Directory Program",
        imageLink: "https://www.bearwithme.live/assets/loader/loader_p.png",
        githubLink: "https://github.com/idallas93/template-engine",
        githubLinkText: "Employee Directory Repository",
        cardText: "A Node CLI that generates a user directory with information about employees and generates an HTML webpage summary.",
        imageAlt: "EmployeeDirectory",
        technologies: "Node.js, Javascript",
        deployedLink: ""
    },
];
exports.imageData = [{
        img: 'https://i.imgur.com/rGtNC0r.png',
        title: 'City'
    },
    {
        img: 'https://i.imgur.com/UVCXdzu.jpg',
        title: 'Breakfast'
    },
    {
        img: 'https://i.imgur.com/cYfGQjM.jpg',
        title: 'Beach'
    },
    {
        img: 'https://i.imgur.com/zLmdpwP.jpg',
        title: 'Hiking'
    },
    {
        img: 'https://i.imgur.com/O1WSJi3.jpg',
        title: 'TennisBall'
    },
    {
        img: 'https://i.imgur.com/VeFjF9u.jpg',
        title: 'BeachTwo'
    },
    {
        img: 'https://i.imgur.com/4rUUI5d.jpg',
        title: 'Vote'
    },
    {
        img: 'https://i.imgur.com/WNLTqlc.png',
        title: 'Alert'
    },
    {
        img: 'https://i.imgur.com/CVmp4hq.jpg',
        title: 'Stella1'
    },
    {
        img: 'https://i.imgur.com/ii2D15V.jpg',
        title: 'Stella2'
    },
    {
        img: 'https://i.imgur.com/bNGuDHZ.jpg',
        title: 'Stella3'
    },
    {
        img: 'https://i.imgur.com/CFmNhWB.jpg',
        title: 'Stella4'
    },
    {
        img: 'https://i.imgur.com/TmvMSwx.jpg',
        title: 'Stella5'
    },
    {
        img: 'https://i.imgur.com/A2i04jz.jpg',
        title: 'Stella6'
    },
    {
        img: 'https://i.imgur.com/BRpa3of.jpg',
        title: 'Stella7'
    },
    {
        img: 'https://i.imgur.com/wFj61Xe.jpg',
        title: 'Stella8'
    },
    {
        img: 'https://i.imgur.com/frlZQsX.jpg',
        title: 'Stella9'
    },
    {
        img: 'https://i.imgur.com/IL8kbU0.jpg',
        title: 'Stella10'
    },
];
