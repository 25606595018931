"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnScreen = void 0;
var react_1 = require("react");
var useOnScreen = function (ref) {
    var _a = (0, react_1.useState)(false), isOnScreen = _a[0], setOnScreen = _a[1];
    var observer = new IntersectionObserver(function (_a) {
        var entry = _a[0];
        return setOnScreen(entry.isIntersecting);
    }, {
        threshold: [0.25, 0.5, 0.75],
    });
    (0, react_1.useEffect)(function () {
        observer.observe(ref.current);
        return function () {
            observer.disconnect();
        };
    });
    return isOnScreen;
};
exports.useOnScreen = useOnScreen;
